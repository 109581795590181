<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="$route.meta.title" :subtitle="$route.meta.description" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-check v-model="filter.cancelled" text="Hide Cancelled" tooltip="Toggle cancelled events" />
		<app-content-filters-select v-model="filter.room" :allow-deselect="true" text="Room" tooltip="Filter by room" :options="$util.options(references.rooms, 'name')" />
		<app-content-filters-select v-model="filter.day" :allow-deselect="true" tooltip="Filter day" text="Day" :options="references.days" />
		<app-content-filters-select v-model="filter.sort" :alwaysLabel="true" :alwaysValue="true" text="Sort by" tooltip="Change sort" :options="filterSort" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no events found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Ref" />
				<app-table-column text="Day" />
				<app-table-column text="Starts" />
				<app-table-column text="Ends" />
				<app-table-column text="Name" />
				<app-table-column text="Seats" align="center" />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :danger="item.cancelled" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.reference" />
					<app-table-cell-text :text="item.date.start | formatDate('dddd')" />
					<app-table-cell-text :text="item.date.start | formatDate('h:mma')" />
					<app-table-cell-text :text="item.date.end | formatDate('h:mma')" />
					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="item.seats.taken + ' / ' + item.seats.total" align="center" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			references: {
				rooms: [],
				days: []
			},
			filterSort: [
				{ value: 'created', text: 'Recently created' },
				{ value: 'name', text: 'Event name' },
				{ value: 'start', text: 'Start time' }
			],
			filter: {
				day: 0,
				room: 0,
				cancelled: 0,
				sort: 'start'
			},
			endpoint: 'convention/schedule/events',
			live: 'convention/schedule/events'
		}

	},

	computed: {

		layout: function() {

			return '64px 100px 80px 80px auto 80px 24px' 

		},

		isProposals: function() {

			return (this.$route.meta.params || {}).proposals === 1

		}

	}

}

</script>

<style scoped>

</style>